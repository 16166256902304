.full {
    height: 100%;
    width: 100%;
    margin: 0;
}

.see_through {
    opacity: 0.8;
}

.content {
    margin: 20px;
}